import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import './footer.css';

const Footer = () => {
    const {
        site: {
            siteMetadata: { title },
        },
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const currentYear = new Date().getFullYear();

    return (
        <footer className="site-footer">
            <p className="site-footer__copyright">
                &copy; 2021 - {currentYear}, {title}. Made with love and perserverence by <a href="https://shffld.com" className="shffld-link" rel="noopener noreferrer" target="_blank">Shffld (This link opens in a new window or tab)</a>.
            </p>

            <nav className="site-footer__nav">
                <a href="https://shffld.com/accessibility/" className="site-footer__link" target="_blank" rel="noopener noreferrer">
                    Accessibility statement
                    <span className="u-vh"> (This link opens in a new window)</span>
                </a>

                <a href="https://shffld.com/privacy/" className="site-footer__link" target="_blank" rel="noopener noreferrer">
                    Privacy and cookies
                    <span className="u-vh"> (This link opens in a new window)</span>
                </a>
            </nav>
        </footer>
    );
};

export default Footer;
