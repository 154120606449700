import React from 'react';

import { graphql, useStaticQuery, Link } from 'gatsby';

import './header.css';

const Header = () => {
    const { site: { siteMetadata: { title }}} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <header className="site-header">
            <Link to="/" className="site-header__logo">
                <span className="site-header__logo-abbr">CSS</span>
                {title}
            </Link>

            <nav className="site-header__nav">
                <Link to="/recipes" className="site-header__link">
                    Recipes
                </Link>

                <Link to="/categories" className="site-header__link">
                    Categories
                </Link>

                <Link to="/stuff" className="site-header__link">
                    Stuff you might need
                </Link>
            </nav>
        </header>
    );
};

export default Header;
