import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/Header';
import Footer from '../footer/Footer';

const Layout = ({ children }) => (
    <div className="site-wrap">
        <Header />

        <main className="u-contain content">
            {children}
        </main>

        <Footer />
    </div>
);

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    location: PropTypes.object,
};

export default Layout;
