import React, { useState } from 'react';

const RecipeContext = React.createContext();

export function RecipeProvider ({ children }) {
    const [recipeIngredients, setRecipeIngredients] = useState([]);

    return (
        <RecipeContext.Provider value={[recipeIngredients, setRecipeIngredients]}>
            {children}
        </RecipeContext.Provider>
    );
}

export default RecipeContext;
