exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-categories-category-page-js": () => import("./../../../src/components/categories/CategoryPage.js" /* webpackChunkName: "component---src-components-categories-category-page-js" */),
  "component---src-components-recipe-recipe-js": () => import("./../../../src/components/recipe/Recipe.js" /* webpackChunkName: "component---src-components-recipe-recipe-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-stuff-js": () => import("./../../../src/pages/stuff.js" /* webpackChunkName: "component---src-pages-stuff-js" */)
}

