import React from 'react';

import { RecipeProvider } from './src/components/provider/RecipeContext';
import Layout from './src/components/layout/Layout';
import './src/styles/global.css';

/**
 * Make sure our layout 'template' is automatically wrapped around every page
 */
export function wrapPageElement ({ element, props }) {
    return (
        <Layout {...props}>
            {element}
        </Layout>
    );
}

/**
 * Get our context providers wrapped around everything, including the above.
 */
export function wrapRootElement ({ element }) {
    return (
        <RecipeProvider>
            {element}
        </RecipeProvider>
    );
}
